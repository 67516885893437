/*
 * Bott-samarbeidet CSS: Local overrides
 */
@import "../../../../../../lib/uio1/profile/css/util.less";
/*------------------------------------*\
# fonts
\*------------------------------------*/
/* latin */
// https://www.fontsquirrel.com/license/poppins
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/poppins-light-italic.woff2") format("woff2");
  // src: url(https://fonts.gstatic.com/s/poppins/v19/pxiDyp8kv8JHgFVrJJLm21lVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/poppins-regular-italic.woff2") format("woff2");
  //  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/poppins-medium-italic.woff2") format("woff2");
  //  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiDyp8kv8JHgFVrJJLmg1hVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/poppins-semibold-italic.woff2") format("woff2");
  //  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiDyp8kv8JHgFVrJJLmr19VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/poppins-light-normal.woff2") format("woff2");
  //  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/poppins-regular-normal.woff2") format("woff2");
  //  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/poppins-medium-normal.woff2") format("woff2");
  //  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/poppins-semibold-normal.woff2") format("woff2");
  //  src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-primary: "Poppins", sans-serif;

#main h1,
#main .vrtx-introduction p {
  max-width: none;
}

#hidnav a[href="#bottomnav"] {
  display: none;
}

a,
#main .add-comment-header,
#comments-header-left a,
#main #vrtx-daily-events .vrtx-resource a.vrtx-title,
#main .vrtx-resources .vrtx-resource a.vrtx-title,
#main #vrtx-daily-events .vrtx-resource a.vrtx-title:hover,
#main .vrtx-resources .vrtx-resource a.vrtx-title:hover,
#main #vrtx-daily-events .vrtx-resource a.vrtx-title:focus,
#main .vrtx-resources .vrtx-resource a.vrtx-title:focus,
#main .vrtx-search-results-container div.vrtx-search-results .result h2 a,
.vrtx-frontpage-box.vrtx-more-false > h2 a {
  color: @color-contrast;
}
p a {
  text-decoration: underline;
}

#breadcrumb-container {
  display: flex;
  width: 970px;
  margin: -40px auto 0;
  padding-bottom: 20px;
  white-space: nowrap;
  column-gap: 10px;
  //display: none;
  span.vrtx-breadcrumb-active,
  span.vrtx-breadcrumb-level a,
  span.vrtx-faculty a,
  span.vrtx-uio a {
    color: @color-font-darker;
    font-family: @font-primary;
    .font-size(15);
  }
}

body {
  font-family: @font-primary;
  .font-size(17);
  color: @color-dark;
}

h1 {
  color: @color-primary;
  font-family: @font-primary;
  font-weight: 500;
  .font-size(29);
}

strong {
  font-weight: 600;
}

.vrtx-frontpage-full-width.total-main
  #main
  .vrtx-frontpage-box:not(.half-box-left):not(.half-box-right):not(.third-box-left):not(.third-box-middle):not(.third-box-right):not(.full-box-center)
  > h2 {
  .font-size(22);
}

.vrtx-frontpage-box.vrtx-more-false > h2 a {
  font-family: @font-primary;
  .font-size(22);
}

.vrtx-introduction {
  font-family: @font-primary;
  .font-size(22);
}

.vrtx-imagedescription {
  .font-size(15);
}

.vrtx-authors,
.vrtx-date-info,
.vrtx-date-info .published-date,
.vrtx-social-components {
  font-family: @font-primary;
  .font-size(16);
  color: @color-font-darker;
}

/* ^ Fonter */

html,
body,
#inner-content-outer-wrapper {
  background-color: @color-background;
}

hr {
  border-bottom: 4px solid @color-border;
}

table td,
table th,
#main #vrtx-daily-events .vrtx-resource,
#main .vrtx-resources .vrtx-resource,
#vrtx-additional-content .vrtx-search-facets-box ul {
  border-color: @color-border;
}

#vrtx-additional-content {
  .vrtx-search-main-links-box,
  .vrtx-search-facets-box {
    ul span {
      background: @color-border;
    }
  }
}

.padding-top-bottom {
  padding: 40px 0;
}

.padding-top {
  padding-top: 40px;
}

.padding-bottom {
  padding-bottom: 40px;
}

.not-for-ansatte #head-wrapper {
  background: url("../images/bott_topp_original1.png") no-repeat center top;
  background-color: #fff;
  height: 280px;
  background-size: cover;
  clear: both;
  width: 100%;
  margin: 0;
  //padding: 30px 0 0;
  //background: @color-primary;
  #head {
    height: 280px;
    margin: 0 auto;
    position: relative;
    width: 970px;
  }
}

.not-for-ansatte .uio-app-name,
.head-menu {
  display: none;
}

#header #logo {
  background: @color-light url("../images/logo.svg") no-repeat scroll 0 0;
  left: 0;
  top: 85px;
  height: 122px;
  width: 200px;
  position: absolute;
  background-size: 100% auto;
  img {
    display: none;
  }
}

.medium-button > span {
  display: none;
}

.not-for-ansatte .header-search {
  top: 60px;
  right: 36px;

  input[type="text"] {
    background: @color-light none repeat scroll 0 0;
    //border: 2px solid #7161e1;
    color: #777777;
    font-size: 1.6rem;
    font-family: @font-primary;
    font-weight: 400;
    height: 50px;
    width: 330px;
    margin-right: -44px;
    .placeholderColor(#777, #aaa);
    &:focus {
      width: 330px;
    }
  }

  button {
    background: rgba(0, 0, 0, 0) url("../images/icon-search.png") no-repeat
      scroll 0 0;
    height: 22px;
    width: 22px;
    padding: 17px 0;
    font-size: 1px;

    &:focus,
    &:hover {
      background: rgba(0, 0, 0, 0) url("../images/icon-search-hover.png")
        no-repeat scroll 0 0;
      height: 23px;
      padding: 16px 0;
      width: 22px;
    }
  }
}

/* Forms */
#main form {
  &.vrtx-search-form,
  &.vrtx-big-search,
  &#enheter,
  &#emner,
  &#personer,
  &.vrtx-search-form,
  &.vrtx-big-search,
  &#enheter,
  &#emner,
  &#personer {
    input[type="search"] {
      border-right: 6em solid @color-background;
      padding-right: 0;
    }
    input[type="submit"] {
      &:before {
        border-color: @color-background;
      }
      &:after {
        background: @color-light;
      }
    }
  }
}

.uio-main ol ul > li:before,
.grid-container ol ul > li:before,
.uio-main ul ul > li:before,
.grid-container ul ul > li:before {
  font-size: 0.7em;
}

.uio-main ul > li:before,
.grid-container ul > li:before {
  top: -0.1em;
  font-size: 0.6em;
}

.vrtx-introduction {
  border-bottom: 4px solid @color-border;
  padding-bottom: 25px;
}

.vrtx-authors {
  border-top: none !important;
}

.vrtx-tags,
.vrtx-authors,
.vrtx-date-info {
  margin: 0;
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search) {
  background-color: @color-primary;
  font-weight: 600;
  &:hover,
  &:focus {
    color: @color-primary;
    background: url("../images/arrow-forward.svg") no-repeat 0 3px
      @color-secondary;
    background-position: 93% 50%;
    background-size: auto 0.8em;
    padding-right: 70px;
    top: 1px;
  }
}

#globalnav {
  height: 60px;
  background: transparent none repeat scroll 0 0;
  ul {
    display: none;
  }
}

#main .row-all-colored,
.vrtx-frontpage-box.grey-box,
.vrtx-frontpage-box.grey-box-light,
.vrtx-frontpage-box.grey-clip-box {
  background: @color-neutral--light;
}

.vrtx-frontpage-box.white-box {
  background: @color-light;
  outline: 15px solid @color-light;
}

// padding for white frontpage-boxes
.grid-container:first-child .vrtx-frontpage-box>h2 {
  padding-top: 40px;
}  
.vrtx-frontpage-box + .vrtx-frontpage-box:first-child > h2 {
  padding-top: 0;
}
.vrtx-frontpage-box {
  padding: 0 20px;
}
#main .grid-container .vrtx-frontpage-box:last-child {
  margin-bottom: 40px;
}
#main .grid-container .vrtx-frontpage-box.grey-box:last-child {
  margin-bottom: 0px;
}

#main
  .grid-container:not(.row-thirds-double)
  .flaggskip.grey-box
  > :first-child {
  padding-top: 10px;
}

.vrtx-frontpage-box {
  &.grey-box.flaggskip {
    background-color: #fff !important;
    padding: 20px 0;
    width: 49%;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    &.left-top {
      margin: 30px 10px 10px 0px !important;
    }
    &.right-top {
      margin: 30px 0px 10px 0px !important;
      float: right;
    }
    &.left-middle {
      margin: 20px 0px 10px 0px !important;
    }
    &.right-middle {
      margin: 20px 0px 10px 0px !important;
      float: right;
    }
    &.left-bottom {
      margin: 20px 0px 30px 0px !important;
    }
    &.right-bottom {
      margin: 20px 0px 30px 0px !important;
      float: right;
    }
    &.left-top,
    &.left-bottom {
      img {
        padding-left: 14px;
      }
    }
    &.right-top,
    &.right-bottom {
      img {
        padding-right: 14px;
      }
    }
    img {
      top: 50%;
    }
    p {
      padding-right: 20px;
    }
    h2 {
      padding-top: 0;
      padding-left: 20px;
    }
  }
}

// Search

#vrtx-searchview #vrtx-content #vrtx-additional-content {
  display: none;
}

#vrtx-searchview #main #total-main #vrtx-main-content {
  width: 100%;
}

.vrtx-topuioresult {
  display: none;
}

/* Tmp fix */

.vcard {
  overflow: visible;
}

// Social components
span.vrtx-social-components-label {
  display: none;
}
.vrtx-social-components {
  margin: -5px 0 0 0;
  float: right;
  a {
    color: @color-font-darker !important;
  }
  .vrtx-email-friend,
  .vrtx-share-at-component {
    a {
      margin: 0;
      padding: 5px 0;
      width: 35px;
      text-indent: -9999px;
    }
    li {
      margin-left: 10px;
    }
  }
  .vrtx-email-friend.vrtx-email-friend,
  .vrtx-share-at-component li.vrtx-email-friend {
    background: rgba(0, 0, 0, 0) url("../images/share-email.png") no-repeat
      scroll left center;
  }
  .vrtx-share-at-component li.vrtx-share-at-Facebook,
  .vrtx-email-friend.vrtx-share-at-Facebook-true,
  .vrtx-share-at-component li.vrtx-share-at-Facebook-true,
  .vrtx-email-friend.vrtx-share-at-FacebookAPI-true,
  .vrtx-share-at-component li.vrtx-share-at-FacebookAPI-true {
    background: rgba(0, 0, 0, 0) url("../images/share-facebook.png") no-repeat
      scroll left center;
  }
  .vrtx-share-at-component li.vrtx-share-at-Twitter,
  .vrtx-email-friend.vrtx-share-at-Twitter-true,
  .vrtx-share-at-component li.vrtx-share-at-Twitter-true {
    background: rgba(0, 0, 0, 0) url("../images/share-twitter.png") no-repeat
      scroll left center;
  }
}

// Left menu
#left-main .vrtx-breadcrumb-menu {
  li.vrtx-child {
    > span:before,
    > a:before {
      font-size: 0.6em;
    }
  }
}
#left-main .vrtx-breadcrumb-menu {
  li a {
    background: @color-light none repeat scroll 0 0;
    color: @color-contrast;
    font-family: @font-primary;
    .font-size(17);
  }
  li > a.vrtx-marked {
    background: @color-primary none repeat scroll 0 0 !important;
    color: @color-light;
  }
  li.vrtx-child {
    a {
      background: @color-light none repeat scroll 0 0;
    }
    > span.vrtx-after-marked,
    > a.vrtx-after-marked,
    > span.vrtx-marked,
    > a.vrtx-marked {
      border-top: 2px solid @color-primary;
    }
  }
  li.vrtx-ancestor > a:after {
    border-color: @color-light transparent transparent;
  }
}

#main .vrtx-back a::before {
  background: rgba(0, 0, 0, 0) url("../images/icon-chevron-back.svg") no-repeat
    scroll 0 2px;
  background-size: 15px 15px;
  margin-bottom: -1px;
}
a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward.svg") no-repeat 0 3px;
  background-size: 20px;
  height: 1em;
  top: 1px;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right.svg") no-repeat
      center left;
    background-size: 17px 17px;
    height: 20px;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down.svg");
    background-size: 17px 17px;
  }
}

// Feeds, misc.

#main {
  margin-top: 60px;
}
#main div.vrtx-feed-link a {
  background: rgba(0, 0, 0, 0) url("../images/rss-feed.png") no-repeat scroll
    left center;
}

.vrtx-tags,
#main .vrtx-paging-feed-wrapper {
  border-top: 4px solid @color-border;
}

.vrtx-subfolder-menu {
  display: none;
}

// Footer
#bottomnav {
  background-color: @color-light !important;
  height: 130px;
  padding: 0;
  border-bottom: 1px solid @color-neutral--light;
  #breadcrumb-container {
    display: none;
  }
}

#footer-wrapper {
  background-color: @color-light !important;
  .font-size(15);
  color: @color-neutral--dark;
  padding-top: 0;
  #footers {
    position: static;
    background-image: none;
    .logo-line {
      margin-top: -141px;
      width: 970px;
      margin-left: -20px;
      a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link) {
        display: inline-block;

        &:hover {
          background-color: transparent;
          .boxShadow(none);
          text-decoration: none;
        }
        &:focus {
          background-color: transparent;
          .boxShadow(0 0 0 2px @color-dark);
        }
      }
    }
  }

  .row {
    a {
      color: @color-neutral--dark;
      text-decoration: underline;
    }
    .col-1-3 {
      padding-top: 30px;
    }
  }

  .menu-label {
    .font-size(15);
  }

  .responsible-login {
    padding-top: 35px;

    .content {
      float: right;
      padding-right: 14px;
      margin-left: 0;
    }
  }

  .vrtx-dropdown-component .vrtx-login-manage-link {
    background-image: url("../uio1/images/dropdown-black.png");
  }
}

body#error #footer-wrapper .logo-line {
  margin-top: 0;
}

/* CSS for responsive design */
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #nav-offcanvas #lang-link {
    display: none;
  }
  h1 {
    .font-size(30);
  }
  .vrtx-frontpage-box.vrtx-more-false > h2 a {
    .font-size(22);
  }
  .vrtx-introduction {
    .font-size(18);
  }

  body #head-wrapper {
    height: 308px !important;
    #head {
      background: url("../images/bott_topp_original1.png") no-repeat center top;
      background-color: #fff;
      background-size: cover;
      clear: both;
      height: 260px !important;
      width: 100%;
      margin: 0;
      #header #logo {
        left: 30px;
        top: 75px;
      }
    }
  }
  body #nav-offcanvas li.vrtx-parent li.vrtx-child:before {
    top: 0.6em;
    font-size: 0.7em;
  }
  body #main {
    padding-bottom: 30px;
  }
  .not-for-ansatte #menu-wrapper .menu {
    background: @color-primary;
    li.active {
      background: @color-primary;
    }
  }

  #main {
    form.vrtx-search-form input[type="text"],
    form.vrtx-big-search input[type="text"],
    form#enheter input[type="text"],
    form#emner input[type="text"],
    form#personer input[type="text"],
    form.vrtx-search-form input[type="search"],
    form.vrtx-big-search input[type="search"],
    form#enheter input[type="search"],
    form#emner input[type="search"],
    form#personer input[type="search"] {
      border-right-width: 5.5em;
    }
  }

  .vrtx-social-components {
    float: none;
  }

  #vrtx-frontpage #main {
    .grid-container.row-last-colored + .row-first-colored,
    .vrtx-frontpage-box.grey-box:not(.responsive-hide) + .grey-box {
      border-top: none;
    }
    .vrtx-frontpage-box:last-child {
      margin-bottom: 40px;
    }
  }

  #bottomnav {
    background: @color-neutral !important;
    height: 0;
  }

  #footer-wrapper {
    #footers {
      padding-top: 0;
      .logo-line {
        margin-top: 20px;
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid;
        text-align: center;
      }
      .col-1-3 {
        padding-top: 30px;
      }
      .responsible-login .content {
        float: none;
        padding-right: 0;
      }
    }
  }
}

@media print {
  html,
  body {
    background-color: transparent !important;
  }
  .not-for-ansatte #head-wrapper {
    height: 150px;
    #head {
      width: 100%;
      #header #logo {
        position: static;
        display: block;
        margin-left: 52px;
        margin-top: 30px;
      }
    }
  }
}

/*------------------------------------*\
# colour
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #32756d; //green // #322958 purple;
@color-primary--light: #9ec5ab; // lighter green
@color-secondary: #bccca8; //slightly mossy bright green
@color-contrast: #9a00b7; // purple

// Neutral colors
@color-neutral--dark: #333333;
@color-font-dark: #555555;
@color-font-darker: #666666;
@color-neutral: #eef2f5; //#dedede;
@color-neutral--lighter: #f4f4f4;
@color-neutral--light: #eef2f5; //#e5e5e5;
@color-border: #c9c9c9;
@color-background: #eef2f5; //#e9e9e9; //#f5f7f8;
// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
